import * as React from "react";
import Seo from "./Seo";

class CognitoForm extends React.Component {
  constructor() {
    super();

    this.scriptTagId = "js-cognitoforms";
  }

  mount() {
    const { formId } = this.props;

    if (typeof window.Cognito !== "undefined") {
      window.Cognito.mount(formId.toString(), `#form-${formId}`);
    }
  }

  componentDidMount() {
    const { formKey, formId } = this.props;

    if (typeof window !== "undefined" && typeof document !== "undefined") {
      if (!document.getElementById(this.scriptTagId)) {
        let el = document.createElement("script");
        el.id = this.scriptTagId;
        el.src = "https://www.cognitoforms.com/f/seamless.js";
        el.setAttribute("data-form", formId.toString());
        el.setAttribute("data-key", formKey);

        el.addEventListener("load", () => {
          this.mount();
        });

        document.head.appendChild(el);
      } else {
        this.mount();
      }
    }
  }

  render() {
    const props = this.props;

    return (
      <div>
        <Seo>
          <script id={ this.scriptTagId } src="https://www.cognitoforms.com/f/seamless.js" data-form={props.formId} data-key={props.formKey}></script>
        </Seo>
        <div id={`form-${props.formId}`}></div>
      </div>
    );
  }
}

CognitoForm.defaultProps = {
  formId: 1,
  formKey: "VeyiV5X9MkCcSVndkFUr3Q",
};

export default CognitoForm;
