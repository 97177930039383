import * as React from "react";
import { useTranslation } from "react-i18next";

// Ours
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import CognitoForm from "../components/CognitoForm";
import Wrapper from "../components/Wrapper";
import Article from "../components/Article";

const Contact = (props) => {
  const { t } = useTranslation();
  const contactFormId = t("contactFormId") || 1;
  const title = t("Contact")

  return (
    <Layout>
      <Seo title={title} />
      <Article title={title}>
        <Wrapper width="narrow">
          <div>{t("contactPreface")}</div>
          <CognitoForm formId={contactFormId} />
        </Wrapper>
      </Article>
    </Layout>
  );
};

export default Contact;
